import { useState, useEffect, useCallback } from 'react';
import './App.css';


function App() {
  const [listNumber, setListNumber] = useState([]);
  const generate = useCallback(() => {
    let dataA = []
    for (let i = 0; i < 50; i++) {
      // console.log(i)
      let angkaA = i;
      if (i < 10) angkaA = `0${i}`
      dataA.push(
        `${angkaA}`
      )
    }
    let dataB = []
    for (let i = 50; i < 100; i++) {
      let angkaB = i;
      if (i < 10) angkaB = `0${i}`
      dataB.push(
        `${angkaB}`
      )
    }
    dataA = dataA.sort(() => Math.random() - 0.5);
    dataB = dataB.sort(() => Math.random() - 0.5);
    const newData = dataA.map((el, index) => {
      const B = dataB[index];
      return {
        A: el,
        B
      }

    })
    setListNumber(newData)
  }, [],)

  useEffect(() => {
    generate()

    return () => {

    }
  }, [generate])
  return (
    <div className="App">
      <header className="App-header">
        <table>
          {/* <thead>
            <tr>
              <th colSpan={2}>
                <button style={{ width: '100%' }} onClick={generate}>Random</button>
              </th>
            </tr>
            {listNumber.map((el, i) => (
              <tr index={i}>
                <th style={{ width: 100 }}>{el.A}</th>
                <th style={{ width: 100 }}>{el.B}</th>
              </tr>
            ))}
          </thead> */}
          <thead>
            <tr>
              <th rowSpan={2}>
                <button style={{ width: '100%', height: '45px' }} onClick={generate}>Random</button>
              </th>
              {listNumber.map((el, i) => (
                <th index={i} style={{ width: 100 }}>{el.A}{(listNumber.length - 1) !== i && "*"}</th>
              ))}
            </tr>
            <tr>
              {listNumber.map((el, i) => (
                <th index={i} style={{ width: 100 }}>{el.B}{(listNumber.length - 1) !== i && "*"}</th>
              ))}
            </tr>
          </thead>
        </table>
      </header>
    </div>
  );
}

export default App;
